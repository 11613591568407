.ai-info-card{
  background: $white;
  border-radius: 15px;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: initial;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  .ai-info-card-icon{
    margin-bottom: 2rem;
    background: $green;
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      width: 100px;
      height: 100px;
    }
    i{
      display: block;
      font-size: 52px;
      color: $white;
    }
  }
  h3{
    color: $primary-blue;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 0 !important;
  }
  &.ai-info-with-paragraph{
    align-items: flex-start;
    text-align: left;
    padding: 2rem;
    width: 100%;
    h3{
      padding-bottom: .8rem;
    }
  }
}

.info-card-carousel{
  @include max-lg{
    padding: 0 !important;
  }
  .slick-dots{
    li{
      button{
        &:before{
          color: rgba($cyan, 0.25);
        }
      }
      &.slick-active{
        button{
          &:before{
            color: $cyan;
          }
        }
      }
    }
  }
  @include max-xs{
    .ai-info-card{
      width: 90%;
    }
  }
}
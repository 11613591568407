.ai-commercial-page{
    .ai-page-header h2{
        max-width: 100%;
    }
    .ai-intro-paragraph{
        .ai-split-line-title-container h2{
            padding-right: 5rem;
            @include max-lg{
                padding-top: 3rem;
                padding-right: 0;
            }
        }
    }
    .why-alumo-tabs.ai-tabs .ai-tab{
        &:nth-child(2), &:nth-child(3){
            .ai-tab-heading{
                padding-right: 3.5rem;
            }
        }
    }
    .ai-customer-experiences{
        .ai-section-subtitle {
            padding-inline: 4%;
        }
    }
}
.ai-calendar-popup-container > div{
    background-color: #0b223ee6;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 100;
    & > div{
        width: 100%;
        //background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
        padding: 2rem;
        button{
            position: absolute;
            top: 2rem;
            right: 3rem;
            border: 0;
            background-color: transparent;
            border-radius: 20px;
            color: #26DCFB;
            align-self: flex-end;
            z-index: 2;
            svg{
                width: 24px;
                height: 24px;
            }
        }
    }
    &.hide-popup{
        display: none;
    }
    &.show-popup{
        display: flex;
    }
}
.ai-commercial-form-wrapper{
    background: white;
    max-width: 80%;
    margin: 0 auto;
    padding-left: 108px;
    padding-right: 108px;
    @include max-md{
        padding: 50px;
    }
    @include max-xs{
        padding: 1rem;
        max-width: 100%;
        max-height: 100vh;
        overflow: scroll;
    }
    form{
        text-align: center;
        hr{
            width: 100%;
            margin: 1rem 0;
        }
        .customer_details{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;
            .ai-form-group{
                width: 30%;
                display: inline-flex;
                flex-direction: column;
                margin-bottom: 1.6rem;
                text-align: left;
                label{
                    color: $primary-blue;
                }
                &.radio{
                    width: 100%;
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin-top: 0.5rem;
                    margin-bottom: 10px;
                    h6{
                        color: $primary-blue;
                        margin-right: 1rem;
                        margin-bottom: 0;
                        line-height: normal;
                    }
                    input{
                        margin-right: 0.4rem;
                    }
                    label{
                        padding-top: 2px;
                    }
                    .invalid-feedback{
                        width: 100%;
                        text-align: left;
                    }
                }
                @include max-md{
                    width: 48%;
                }
                @include max-sm{
                    width: 100%;
                    margin-bottom: 1rem;
                }
            }
        }
        .terms{
            text-align: left;
            margin-top: 1rem;
            margin-bottom: 48px;
            label{
                color: $primary-blue;
                padding-top: 2px;
                a{
                    &:hover{
                        color: $cyan !important;
                    }
                }
            }
            @include max-xs{
                margin-right: 0;
                input{
                    margin-right: 0 !important;
                }
            }
        }
    }
    button[type="submit"]{
        border:2px solid #00E881;
        background-size: 230% 23    0%;
        position: static;
        color: $primary-blue;
    }
}


.ai-testimonial-card{
  width: 90%;
  margin: 0 auto;
  @include max-md{
    width: 95%;
    margin: 0 auto;
  }
  .ai-testimonial-content{
    background-color: $white;
    font-size: 20px;
    padding: 2rem 3rem 3rem 3rem;
    text-align: left;
    color: $blue;
    line-height: 38px;
    border-radius: 15px;
    position: relative;
    margin-bottom: 2.5rem;
    @include max-xs{
      font-size: 14px;
      line-height: 24px;
      padding: 2rem;
    }
    span.ai-testimonial-name {
      position: absolute;
      background-color: $blue;
      color: $white;
      font-weight: 600;
      height: 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 3rem;
      left: 0;
      bottom: -1.5rem;
      border-radius: 0 15px 15px 0;
      font-size: 18px;
      @include max-xs{
        font-size: 14px !important;
        padding: 0 2rem;
        height: 2.5rem;
        bottom: -1rem;
      }
    }
  }
  .ai-testimonial-details{
    display: flex;
    flex-direction: row;
    span{
      display: flex;
      align-items: center;
      color: $blue;
      font-weight: 400;
      font-size: 14px;
      i{
        font-size: 28px;
        margin-right: .5rem;
      }
      &:first-child{
        margin-right: 3rem;
      }
    }
    @include max-xs{
      justify-content: space-between !important;
      span{
        font-size: 10px;
        i{
          margin: 0 !important;
        }
        &:first-child{
          margin-right: 0rem;
        }
      }
    }
  }
}
span.ai-link-container {
  display: flex;
  align-items: center;
  .ai-link {
    text-decoration: none;
    color: $white;
    display: inline-flex;

    .ai-link-animation-container {
      height: 24px;
      overflow: hidden;
    }
    //  Hover Styles
    &:hover {
      color: $green;
      i {
        color: $green;
      }
    }
  }
  i {
    font-size: 16px;
    padding-left: .5rem;
  }
}
footer.ai-main-footer{
  background: $blue;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100vw;
  .ai-footer-top-row, .ai-footer-bottom-row{
    display: flex;
    align-items: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
    display: flex;
    justify-content: space-between;
  }
  .contact-detail-row{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    @include max-xs{
      margin: 0 0 2rem 0;
    }
    a{
      display: inline;
      width: auto;
      padding-right: 0;
      padding-left: 0;
      text-decoration: none;
      position: relative;
      overflow: hidden;
    }
    .ai-contact-icon{
      position: relative;
      overflow: hidden;
      border: 2px solid $green;
      border-radius: 15px;
      height: 50px;
      width: 50px;
      display: inline-flex !important;
      justify-content: center;
      align-items: center;
      z-index: 10;
      background: radial-gradient($green 45%, transparent 45%);
      background-size: 400% 275%;
      background-position: 105% 0%;
      background-repeat: no-repeat;
      transition: background-position .5s ease !important;
      i{
        color: $green;
        font-size: 20px;
        z-index: 9;
        transition-delay: .1s;
      }
      &:after{
        //content:"";
        //height: 10px;
        //width: 10px;
        //background: $green;
        //position: absolute;
        //border-radius: 100%;
        //left: -10px;
        //bottom: -10px;
        //transition: transform .5s ease;
      }
    }
    .ai-contact-text{
      color: $white;
      width: auto;
      padding-left: .8rem;
      span.ai-contact-link{
        font-weight: 600;
        font-size: 20px;
        display: block;
        a{
          text-decoration: none;
          color: $green;
        }
      }
    }
    &:hover{
      .ai-contact-icon{
        background-position: 50% 50%;
        i{
          color: $blue;
        }

        &:after{

      }
      }
    }
  }
  .ai-footer-social-icon-row{
    display: flex;
    justify-content: space-evenly;
    a{
      display: inline-block;
      width: auto;
      padding-left: 0;
      text-decoration: none;
      @include max-xs{
        z-index: 0 !important;
      }
    }
    .ai-footer-social-icon{
      width: 50px;
      height: 50px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border: 2px solid $cyan;
      border-radius: 15px;
      position: relative;
      overflow: hidden;
      text-decoration: none;
      z-index: 10;
      background: radial-gradient($cyan 45%, transparent 45%);
      background-size: 400% 275%;
      background-position: 105% 0%;
      background-repeat: no-repeat;
      transition: background-position .5s ease !important;
      &:hover{
        background-position: 50%;
      }
      i{
        color: $white;
        font-size: 20px;
        transition-delay: .1s;
        z-index: 9;
      }
      &:after{
        content:"";
        height: 10px;
        width: 10px;
        background: $cyan;
        position: absolute;
        border-radius: 100%;
        left: -10px;
        bottom: -10px;
        transition: transform .5s ease;
      }
    }
    @include max-xs{
      justify-content: flex-start !important;
    }
  }
  .ai-footer-bottom-row{
    border-top: 1px solid $cyan;
    padding-top: 3rem;
    padding-bottom: 3rem;
    .ai-copyright-text{
      p{
        font-size: 12px;
        color: $white;
        max-width: 90%;
        margin-bottom: 0 !important;
        a{
          text-decoration: none;
          color: $white;
          &:hover{
            color: $green;
          }
        }
      }
    }
  }
  .ai-footer-navigation{
    .row{
      padding: 0;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      font-size: 14px;
      .ai-link-container{
        width: auto;
        display: inline-block;
        padding-left: 1rem;
      }
    }
  }
  @include max-lg{
    .ai-footer-top-row{
      .ai-footer-brand{
        img{
          width: 100%;
        }
      }
      .contact-detail-row{
        .ai-contact-icon {
          border-radius: 10px;
          width: 40px;
          height: 40px;
          @include max-xs{
            z-index: 0;
          }
        }
        .ai-contact-text{
          font-size: 14px;
          //width: auto;
          .ai-contact-link{
            font-size: 14px;
          }
        }

      }
      .ai-footer-social-icon-row{
        .ai-footer-social-icon{
          height: 40px;
          width: 40px;
          border-radius: 10px;
        }
      }
    }
  }
  @include max-md{
    .ai-footer-top-row{
      .col-md-7{
        display: flex;
        justify-content: center;
      }
    }
    .ai-footer-bottom-row{
      display: flex;
      flex-direction: column-reverse;
      .ai-footer-navigation{
        width: 100%;
        margin-left: 0;
        display: flex;
        justify-content: flex-end;
        @include max-md{
          justify-content: center;
          .row{
            width: 100%;
            justify-content: space-between;
            .ai-link-container{
              padding: 0;
            }
          }
        }
      }
      .ai-copyright-text{
        margin-top: 1rem;
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
  @include max-xs{
    position: static;
    .ai-footer-top-row{
      .ai-footer-brand{
        width: 40% !important;
        margin-bottom: 1.5rem;
      }
      .col-md-7{
        .row{
          margin-left: 0;
          .col-md-6{
            padding-left: 0;
            margin-bottom: 1.5rem;
          }
        }
      }
      .ai-footer-social-icon-row{
        margin-left: 0;
        display: flex;
        justify-content: flex-start;
        .ai-footer-social-icon{
          margin-right: .5rem;
        }
      }
    }
    .ai-footer-bottom-row{
      padding-bottom: 1rem;
      .ai-footer-navigation{
        justify-content: flex-start;
        .row{
          display: flex;
          flex-direction: column;
          .ai-link-container{
            margin-bottom: 1rem;
            @include max-xs{
              margin-bottom: 0 !important;
              padding-left: 0;
            }
          }
        }
      }
      .ai-copyright-text{
        display: flex;
        justify-content: flex-start;

      }
    }
  }
}
body {
  background-color: $grey !important;
}
//typography
*{
  font-family: 'Poppins', sans-serif;
}
p {
  margin-bottom: 2rem !important;
}
h3{
  font-weight: 600;
}
.ai-font-bold{
  font-weight: 600 !important;
}
.ai-font-medium{
  font-weight: 500 !important;
}
svg{
  display: inline-block;
}
.ai-section-title{
  font-weight: 600;
  font-size: 42px;
  padding-bottom: 1rem ;

  &.ai-title-small{
    font-size: 24px;
  }
  &.ai-title-xsmall{
    font-size: 18px;
  }
  &.ai-pb-clr{
    padding-bottom: 0;
  }
  @include max-xs{
    font-size: 32px;
    line-height: 42px;
  }
}
.ai-section-subtitle{
  font-weight: 600;
  font-size: 24px;
  padding-top: .8rem;
  color: $blue;
  line-height: 34px;
  &.ai-pt-clr{
    padding-top: 0;
  }
  @include max-xs{
    font-size: 20px;
    line-height: 30px;
  }
}
.ai-section-subtitle-small{
  font-size: 20px;
  color: $blue;
  line-height: 30px;
  strong{
    font-weight: 600;
  }
  @include max-md{
    font-size: 16px;
    line-height: 26px;
  }
}
h2.ai-text-small-big-em{
  font-size: 35px !important;
  em{
    font-style: normal;
    font-size: 40px;
  }
}
.ai-section-subtitle-xsmall{
  font-size: 16px;
  font-weight: 400;
  color: $blue;
  line-height: 30px;
}
.ai-lh-30{
  line-height: 30px;
}
.ai-lh-55{
  line-height: 55px;
}
.page-content{
  position: relative;
  margin-bottom: 318px;
  background: $white;
  max-width: 100vw;
  width: 100%;
  z-index: 2;
  @include max-lg{
    position: relative;
    z-index: 2;
    background: $white;
    margin-bottom: 287px;
  }
  @include max-md{
    margin-bottom: 355px;
  }
  @include max-xs{
    position: relative;
    margin-bottom: 0px;
    background: $white;
    z-index: 2;
  }
}

.ai-section-heading{
  @include max-xs{
    padding: 0 !important;
  }
}


// default font sizes
.ai-a {
  font-size: 14px !important;
}
.f-12{
  font-size: 12px !important;
}
.f-14{
  font-size: 14px !important;
}
.f-16{
  font-size: 16px !important;
}
.f-18{
  font-size: 18px !important;
}
.f-20{
  font-size: 20px !important;
}
.f-25{
  font-size: 25px !important;
}

.ai-p {
  font-size: 14px !important;
  line-height: 24px;
  &.p-16{
    font-size: 16px !important;
    line-height: 26px;
  }
}

.font-w-400{
  font-weight: 400 !important;
}

.ai-h1 {
  font-size: 65px !important;
  font-weight: 600;
}

.ai-h2 {
  font-size: 40px !important;
  font-weight: 600;
  &.h2-42{
    font-size: 42px !important;
  }
  @include max-md{
    font-size: 36px !important;
  }
}

.ai-h3 {
  font-size: 24px !important;
  font-weight: 600;
  @include max-xs{
    font-size: 18px !important;
  }
}

.ai-h4 {
  font-size: 20px !important;
  font-weight: 600;
}

.ai-h5 {
  font-size: 40px !important;
}

.ai-h6 {
  font-size: 30px !important;
}

h6{
  font-weight: 600 !important;
}

.ai-lh-34{
  line-height: 34px !important;
}

//background colors for

.ai-bg-blue {
  background-color: $blue;
}
.ai-bg-cyan-0-1{
  background-color: rgba($cyan, 0.1) !important;
}
.ai-bg-cyan-0-2{
  background-color: rgba($cyan, 0.2) !important;
}
.ai-bg-cyan-0-3{
  background-color: rgba($cyan, 0.3) !important;
}
.ai-bg-cyan-0-5{
  background-color: rgba($cyan, 0.5) !important;
}
.ai-bg-none{
  background: none;
}
//text colors

.ai-text-grey {
  color: $grey !important;
}
.ai-primary-grey {
  color: $primary-grey !important;
}
.ai-text-white{
  color: $white;
}
.ai-text-cyan {
  color: $cyan !important;
}
.ai-secondary-cyan {
  color: $secondary-cyan !important;
}
.ai-text-green {
  color: $green !important;
}

.ai-text-primary-green {
  color: $primary-green !important;
}

.ai-text-blue {
  color: $primary-blue !important;
}
h1.ai-text-blue,h2.ai-text-blue,h3.ai-text-blue,h4.ai-text-blue,h5.ai-text-blue,h6.ai-text-blue{
  color: $blue !important;
}
.ai-text-primary-blue {
  color: $primary-blue !important;
}

.ai-text-secondary-blue {
  color: $secondary-blue !important;
}

// borders {
  .ai-border-green {
    border: 2px solid $green;
  }

  .ai-border-cyan {
    border: 2px solid $cyan;
  }



// full background image
.ai-border-15{
  border-radius: 15px !important;
}
.ai-full-background-img {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
}

.ai-full-background-img-no-size {
  background-position: center center;
    background-repeat: no-repeat;
}

// 
.ai-page-header-overlay {
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
}


//page height
.page-content {
  min-height: 100vh;
}

//Background
.ai-bg-blue{
  background-color: #0C233D;
}
.ai-bg-offwhite{
  background-color: $grey;
}
.ai-bg-green{
  background-color: $primary-green;
}

.ai-gradient-text{
  background: linear-gradient(107deg, $green 0%, $cyan 100%) 0% 0% no-repeat padding-box;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}
//Container Settings
section{
  position: relative;
  scroll-margin: 5rem !important;
}
section.container-fluid{
  padding-left: 0;
  padding-right: 0;
}
.container{
  @include max-lg{
    &.full-width-container{
      max-width: 100%;
      margin-left: 0px !important;
      margin-right: 0px !important;
      padding-right: 0px !important;
      padding-left: 0px !important;
      h2{
        padding-left: 2rem;
        padding-right: 2rem;
      }
      p{
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }
  }
  @include max-xs{
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
}
//Center Align Center
.container.ai-center-align{
  text-align: center;
}

.ai-text-center{
  text-align: center;
}
//Row Setting
.row{
  padding-top: 6rem;
  padding-bottom: 6rem;
  max-width: 100%;
  @include max-xs{
    padding-bottom: 4rem;
    padding-top: 4rem;
  }
  &.ai-padding-clr{
    padding: 0;
  }
  &.ai-pb-clr{
    padding-bottom: 0;
  }
  &.ai-pt-clr{
    padding-top: 0;
  }
  .row{
    padding-top: 0;
    padding-bottom: 0;
  }
  .ai-overflow-h{
    overflow: hidden;
  }
  &.row-gap{
    flex: unset;
    display: flex;
  }
  &.ai-lg-column{
    @include max-lg{
      flex-direction: column;
      div{
        padding: 0;
        width: 100%;
      }
    }
  }
  &.ai-lg-column-reverse{
    // Mobile row column layouts
      @include max-lg{
        flex-direction: column-reverse;
      }
  }
  &.ai-md-column{
    @include max-md{
      flex-direction: column;
      div{
        padding: 0;
        width: 100%;
      }
    }
  }
}

.ai-m-no-offset-full{
  @include max-md{
    margin-left: 0 !important;
    width: 100% !important;
  }
}

.ai-lg-no-offset-full{
  @include max-lg{
    margin-left: 0 !important;
    width: 100% !important;
  }
}

//Margin helpers
.ai-mb-clr{
  margin-bottom: 0rem !important;
}
.ai-mb-1{
  margin-bottom: 2rem;
}
.ai-mb-2{
  margin-bottom: 2rem;
}
.ai-mb-3{
  margin-bottom: 3rem;
}
.ai-ml-clr{
  margin-left: 0 !important;
}
.ai-ml-1{
  margin-left: 1rem;
}
.ai-ml-2{
  margin-left: 2rem;
}
.ai-mt-3{
  margin-top: 3rem;
}

.ai-mr-clr{
  margin-right: 0 !important;
}

.ai-mr-2{
  margin-right: 2rem;
}

@include max-lg {
  .ai-t-mr {
    &-0 {
      margin-right: 0;
    }
  }

  .ai-t-ml {
    &-0 {
      margin-left: 0;
    }
  }
}


//Padding Helpers
.ai-pb-0{
  padding-bottom: 0 !important;
 }
.ai-pb-1{
  padding-bottom: 1rem;
}
.ai-pb-2{
  padding-bottom: 2rem;
}
.ai-pb-3{
  padding-bottom: 3rem;
}
.ai-pb-4{
  padding-bottom: 4rem;
}
.ai-pb-6{
  padding-bottom: 6rem;
}
.ai-pt-1{
  padding-top: 1rem;
}
.ai-pt-2{
  padding-top: 2rem;
}
.ai-pt-3{
  padding-top: 3rem;
}
.ai-pt-4{
  padding-top: 4rem;
}
.ai-pt-0-5{
  padding-top: 0.5rem;
}

.ai-pl-0{
  padding-left: 0rem !important;
}
.ai-pl-1{
  padding-left: 1rem;
}
.ai-pl-2{
  padding-left: 2rem;
}
.ai-pl-3{
  padding-left: 3rem !important;
}
.ai-pl-4{
  padding-left: 4rem;
}

.ai-pr-0{
  padding-right: 0rem;
}
.ai-pr-1{
  padding-right: 1rem;
}
.ai-pr-2{
  padding-right: 2rem;
}
.ai-pr-3{
  padding-right: 3rem;
}
.ai-pr-4{
  padding-right: 4rem !important;
}
.ai-pr-5{
  padding-right: 5rem !important;
}
.ai-pr-6{
  padding-right: 6rem !important;
}
.ai-pr-7{
  padding-right: 7rem !important;
}

//mobile padding
.ai-m-p-clr{
  @include max-lg{
    padding: 0 !important;
  }
}

.ai-m-pt-clr{
  @include max-xs{
    padding-top: 0 !important;
  }
}
.ai-m-pr-clr{
  @include max-md{
    padding-right: 0 !important;
  }
}

.ai-m-pt-2{
  @include max-xs{
    padding-top: 2rem !important;
  }
}

//mobile margins
.ai-m-m-clr{
  @include max-md{
    margin: 0 !important;
  }
}
.ai-m-ml-clr{
  @include max-md{
    margin-left: 0 !important;
  }
}
.ai-lg-m-auto{
  @include max-lg{
    margin: 0 auto !important;
  }
}

.ai-pl-1-5 {
  padding-left: 1.5rem;
}
//Max-width
.ai-mw-60{
 max-width: 60%;
}
.ai-mw-65{
  max-width: 65%;
}

.ai-mw-80 {
  max-width: 80%;
}
.ai-mw-82{
  max-width: 82%;
}



//images
img.ai-img-center{
  display: block;
  margin: 0 auto;
}
img.ai-img-80{
  max-width: 80%;
  width: 100%;
  height: auto;
}
img.ai-img-85{
  max-width: 85%;
  width: 100%;
  height: auto;
}

img.ai-img-90{
  max-width: 90%;
  width: 100%;
  height: auto;
}
img.ai-img-100{
  max-width: 100%;
  width: 100%;
  height: auto;
}
img.ai-img-30{
  max-width: 30%;
  width: 100%;
  height: auto;
}

img.ai-img-right{
  display: block;
  margin: auto 0 auto auto;
}


//Split line headings
.ai-split-line-title-container{
  overflow: hidden;
  height: auto;
  h2{
    padding-bottom: 0;
  }
}

.animation-paragraph-container{
  height: auto;
  overflow: hidden;
}

//Temp Grid
.temp-grid{
  position: fixed;
  height: 100vh;
  z-index: 999999999;
  width: 100%;
  top: 0;
  pointer-events: none;
  .row{
    padding-top: 0;
  }
  .ai-grid-col{
    background: rgba($cyan, 0.2);
    height: 100vh;
    border-left: 1px solid $green;
    border-right: 1px solid $green;
  }
}


.ai-link-card-row{
  flex: unset;
  flex-wrap: nowrap;
  gap: 30px;
  div{
    flex: 1;
  }
}

//Hide On Desktop
.ai-desktop{
  display: block;
  @include max-lg{
    display: none;
  }
}
.ai-mobile{
  display: none;
  @include max-lg{
    display: block;
  }
}

@media(min-width: 1921px){
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{
    max-width: 1920px !important;
  }
}

.calculator-section-pb-clr{
  .row:nth-child(2){
    padding-bottom: 0;
  }
}
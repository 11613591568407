//Product details
.ai-product-details{
  h3{
    margin-bottom: 2rem;
    color: $blue;
    font-weight: 600;
    line-height: 34px;
    @include max-xs{
      line-height: 26px;
    }
  }
  p{
    line-height: 28px;
    margin-bottom: 2.5rem !important;
  }

}
//Product Image
.ai-product-image{
  overflow: hidden;
  img{
    width: 83%;
    height: auto;
    margin: auto !important;
  }
  @include max-md{
    img{
      margin: 0 auto;
    }
  }
}
//Curve
.ai-product-benefits-curve{
  background-position-y: -85px;
  background-repeat: no-repeat;
  background-size: contain;

  &.ai-products-customer-section{
    background-position-y: 0;
  }
  @include max-lg{
    background-size: auto 350px !important;
    background-position: top center !important;
  }
  @include max-xs{
    background-size: auto 250px !important;
    background-position: top center !important;
    padding-bottom: 2rem;
  }
  .ai-product-info-cards{
    justify-content: space-evenly !important;
  }
}
.ai-product-spec-row{
  justify-content: space-between;
  .ai-product-spec{
    display: flex;
    font-size: 16px;
    color: $blue;
    padding: 1rem 0;
    position: relative;
    width: 45%;
    justify-content: space-between;
    @include max-lg{
      width: 100% ;
      justify-content: start !important;
      display: block;
      &:after{
        width: 70% !important;
        left: 0;
        right: 0;
        margin: auto;
      }
    }
    @include max-md{
      &:after{
        width: 85% !important;
      }
    }
    @include max-xs{
      text-align: left;
      span{
        margin-bottom: 5px;
        margin-right: 1rem;
        display: block !important;
        text-align: left;
        width: 100% !important;
        
      }
      &:after{
        width: 100% !important;
      }
    }
    &:after{
      content: '';
      width: 100%;
      position: absolute;
      height: .5px;
      background: rgba($blue, 0.2);
      bottom: 0;
      left: 0;

    }
    .ai-product-spec-name{
      color: $green;
      font-weight: 600;
      width: auto;
      text-align: left;
    }
    .ai-spec-content{
      margin-bottom: 0 !important;
    }
  }
}

.ai-product-info-cards{
  max-width: 96% !important;
  margin: 0 auto !important;
  @include max-md{
    max-width: 100% !important;
  }
}


.ai-promotion-content{
  .ai-section-heading{
    margin-bottom: 2rem;
    padding-bottom: 0;
    .ai-section-title{
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
  p{
    color: $blue;
  }
  img{
    border: 15px;
  }
}
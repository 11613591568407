.ai-homepage-banner{
  height: 100vh;
  //background-color: $grey;
  background: transparent;
  position: relative;
  background-size: 100%;
  .ai-home-overlay-container{
    position: absolute;
    height: 100%;
    width: 100%;
    &:before{
      content: "";
      height: 100%;
      width: 100%;
      //background: radial-gradient(#00B564 0%, #0B223C 100%) 0% 0% no-repeat padding-box;
      position: absolute;
      opacity: 0.5;
      clip-path: ellipse(85% 100% at 50% 0%);

    }
  }


  @include max-lg{
    height: 55vh;
  }
  @include  max-md{
    height: auto;
  }
  @include xl-height{
    height: unset;
  }
  .ai-home-banner-arrow{
    position: static;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    bottom: .5rem;
    i{
      color: $white;
      font-size: 26px;
      animation: arrowAnimation 3s ease-in-out infinite;
    }
    @include max-md{
      display: none;
    }
  }

  .ai-home-page-clip-path{
    background-color: $grey;
    height: 100%;
    width: 100%;
    clip-path: ellipse(85% 100% at 50% 0%);
    background-size: 102%;
    background-position: top;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    .ai-content-container{
      z-index: 1;
      text-align: center;
      h1{
        font-size: 60px;
        font-weight: 700;
        margin-bottom: 1.8rem;
        em{
          font-style: normal;
        }
      }
      p{
        line-height: 40px;
        font-size: 18px;
        strong{
          font-weight: 600;
        }
        em{
          font-style: normal;
          color: $green;
          font-weight: 600;
        }
        @include min-xl{
          max-width: 680px;
          margin: 0 auto;
        }

      }

      @include max-md{
        width: 100%;
      }
    }
    .ai-home-banner-watermark{
      width: auto;
      height: 130dvh;
      position: absolute;
      bottom: 0;
      left: -5%;
      z-index: 0;
      opacity: 0.2;
      animation: waterMark 180s ease infinite;
      @media screen and (min-height: 800px) {
        bottom: 10vh;
        height: 100dvh;
      }
    }
    @keyframes waterMark {
      0%{
        transform: translatex(0) rotate(0);
      }
      50%{
        transform: translatex(20%) rotate(90deg);
      }
      100%{
        transform: translatex(0) rotate(0);
      }
    }
    @include max-lg{
      background-position: top;
      background-repeat: no-repeat;
    }
    @media (min-width: 1921px){
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    @include max-lg{
      background-size: cover;
      .ai-content-container{
        padding-top: 4rem;
        padding-bottom: 15rem;
      }
      .ai-home-banner-watermark{
        height: 50dvh;
        top: unset;
        bottom: 0;
      }
    }
    @include max-sm{
      .ai-content-container{
        h1{
          font-size: 45px;
        }
      }
    }
  }

  .ai-slider-container{
    position: absolute;
    left: 0;
    right: 0;
    bottom: -30px;
    @include max-md{
      bottom: -100px !important;
    }
    @include xl-wide{
      bottom: -25px;
    }
  }

//  Responsive
//  FHD
  @include fhd{
    .ai-home-page-clip-path{
      background-position: right;
    }
  }
}


.ai-homepage-tabs{
  .ai-tabs{
    .ai-tab-content{
      border-radius: 15px;
      padding: 0;
    }
    .ai-tab-list{
      margin-top: 1rem;
      display: flex;
      .ai-tab{
        border-top: 2px solid $cyan;
        border-bottom: 0;
        border-radius: 0 0 15px 15px;
        flex: 1;
        @include max-xs{
          vertical-align: top;
        }
        span.ai-tab-heading{
          color: $cyan;
          font-weight: 600;
        }
        span.ai-tab-text{
          color: $cyan;
        }
        &:hover{
          span.ai-tab-heading{
            color: $blue;
          }
          span.ai-tab-text{
            color: $blue;
          }
        }
        &.react-tabs__tab--selected{
          span.ai-tab-heading{
            color: $blue;
          }
          span.ai-tab-text{
            color: $blue;
          }
        }
      }
    }
  }
}

.ai-home-page-reviews{
  background-size: cover;
  .ai-reviews-stars{
    img{
      width: 100%;
      height: auto;
    }
  }
  .ai-section-title{
    padding-left: 2rem;
    padding-right: 2rem;
    .ai-word-container{
      @include max-xs{
        line-height: 1.35;
      }
    }
    em{
      color: $green;
      font-weight: 600;
    }
  }
  .testimonial-slider{
    & > .container{
      & > .row{
        padding-bottom: 0;
      }
    }
  }
  .testimonial-slider-container{
    .ai-testimonial-details{
      .ai-testimonial-location{
        color: $white;
      }
      .ai-testimonial-type{
        color: $white;
      }
    }
    .slick-dots li{
      button:before{
        color: rgba($white, 0.5);
      }
      &.slick-active{
        button:before{
          color: $white;
        }
      }
    }
  }
  @include max-sm{
    .ai-section-heading{
      h2{
        padding-left: 0;
        padding-right: 0;
      }
      h2.ai-text-small-big-em{
        font-size: 32px !important;
        em{
          font-size: 36px;
        }

      }
    }
  }
}

.ai-home-page-subheading{
  overflow: hidden;
  h1{
    font-size: 35px !important;
    font-weight: 600 !important;
    line-height: 35px !important;
    margin-bottom: 1rem !important;
  }
}

.ai-home-page-heading{
  overflow: hidden;
  @include max-lg{
    h1{
      white-space: nowrap;
    }
  }
}

@keyframes arrowAnimation {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0px);
  }

}

//HOW WE DO ENERGY
.ai-how-we-do-energy-home{
  overflow: hidden;
  .ai-how-we-do-energy-clip-path-container{
    clip-path: ellipse(100% 100% at 50% 100%);
    background-color: $blue;
    background-size: cover;
    background-position: center;
    @include max-sm{
      clip-path: ellipse(150% 100% at 50% 100%);
    }
  }
  .ai-how-we-do-energy-items{
    img{
      width: 100%;
      height: auto;
    }
    p.small{
      font-size: 12px;
    }
    .how-we-do-energy-slider{
      overflow: visible;
      .slick-list{
        overflow: visible;
        .slick-track{
          //display: flex;
          //align-items: center;
        }
        .slick-slide{
          .ai-slider-image-container{
            width: 100%;
            overflow: hidden;
            display: block;
          }
          img{
            width: calc(100% + 1.2px);
            object-fit: cover;
            height: auto;
            //object-fit: cover;
            //height: auto;
          }
        }
      }
    }
  }
  .mobile{
    display: none;
  }
  @include max-lg{
    .desktop{
      display: none;
    }
    .mobile{
      display: block;
    }
  }
}

//Get Switched On
.get-switched-on-home{
  position: relative;
  padding-top: 12rem;
  margin-top: -12rem;
  z-index: -1;
}

#option-section{
  scroll-margin-top: 0 !important
}
.ai-promotions{
  display: flex;
  align-items: center;
  .ai-promotion-image{
    border-radius: 15px;
    width: 98%;
  }
  .ai-promotion-details{
    h4{
      font-weight: 600;
      padding-bottom: 1rem;
      margin-bottom: 0;
    }
    p{
      margin-bottom: 0 !important;
      padding-bottom: 1.5rem;
    }
  }
  @include max-md{
    flex-direction: column;
    width: 100%;
    margin: 0;
    .col-md-8{
      width: 100%;
      padding: 0;
      img{
        width: 100%;
      }
    }
    .col-md-4{
      width: 100%;
      margin-top: 1rem;
      padding: 0;
    }

  }
}
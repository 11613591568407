.what-we-do-intro{
    font-size: 40px !important;
  @include max-xs{
    font-size: 30px !important;
  }
  a{
    color: $green;

    &:first-child{
      white-space: nowrap;
    }
    &:hover{
      color: $cyan;
    }
  }

  @include max-xs{
    a {
      &:first-child {
        white-space: normal !important;
        display: block !important;
      }
    }
  }
}
.ai-get-switched-title{
  font-size: 24px !important;
  padding-bottom: .1rem;
  font-weight: 400 !important;
    .ai-word-container{
      span{
        margin-bottom: 1rem !important;
      }
    }
  @include max-xs{
    line-height: .8em !important;
    .ai-word-container{
      line-height: .8em;
    }
  }
}
.ai-get-switched-subtitle{
  font-size: 40px ;
  @include max-xs{
    line-height: 50px;
  }
}

.ai-link-card-container {
  a{
    height: 100%;
  }
  @include max-md {
    width: 50% !important;
    margin-left: 0 !important;
  }
  @include max-xs{
    width: 100% !important;
    margin-bottom: 1rem;
    &:last-child{
      margin-bottom: 0;
    }
  }
}

.ai-what-we-do-video{
  .ai-video-section{
    border-radius: 15px;
    overflow: hidden;
    height: 80vh;
    @include max-lg{
      height: fit-content;
    }
    @include widescreen{
      height: 70vh;
    }
    video{
      object-position: 0;
    }
  }
}

.ai-gradient-line{
  height: 4px;
  width: 100%;
  background: linear-gradient(90deg, #00E881 0%, #26DCFB 100%) 0% 0% no-repeat padding-box;
  position: absolute;
  bottom: -1rem;
}
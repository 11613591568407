.ai-brand-info-blocks{
  padding-bottom: 0;
  max-width: 94%;
  margin: 0 auto !important;
  .col-md-4{
    margin-bottom: 2rem;
  }
  .ai-info-card{
    height: 100%;
    margin-bottom: 2rem;
    width: 95%;
    margin: 0 auto;
    justify-content: flex-start;
  }
  @include max-md{
    margin: 0;
    .col-md-4{
      margin-bottom: 1rem;
      width: 100%;
    }
    .ai-info-with-paragraph{
      width: 100%;
    }
  }
}

.ai-product-card{
  margin-bottom: 2rem;
}

.ai-brand-logo{
  @include max-md{
    width: 100% !important;
  }
  @include max-xs{
    img{
      max-width: 60% !important;
    }
  }
}

.ai-brand-intro{
  @include max-md{
    width: 100% !important;
  }
}

.suggested-products{
  @include max-md{
    .col-md-4 {
      width: 50%;
    }
  }
  @include max-xs{
    .col-md-4{
      width: 100%;
      .ai-post-card{
        width: 100%;
      }
    }
  }
}
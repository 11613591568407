.ai-button-row{
  display: inline-flex;
  flex-direction: row;
  width: fit-content;
  .ai-button{
    margin: 0 1rem;
  }
  @include max-xs{
    flex-direction: column !important;
    .ai-button{
      margin-bottom: 1rem !important;
      &:last-child{
        margin-bottom: 0 !important;
      }
    }
  }
}

.ai-button{
  color: $blue;
  text-decoration: none;
  display: inline-block;
  width: auto;
  border-radius: 100px;
  border: 2px solid $green;
  font-family: $ai-font-poppins ;
  font-weight: 600;
  font-size: 16px;
  padding: 8px 30px;
  position: relative;
  overflow: hidden;
  z-index: 2;
  @include max-xs{
    font-size: 14px;
    padding: 8px 18px;
  }
  //Saving Button
  span.ai-btn-icon{
    margin-left: .7rem;
    display: inline-block;
    z-index: 2;
    pointer-events: none;
    svg{
      display: inline-block;
      width: 16px;
      @include max-xs{
        width: 14px
      }
      path{
        fill: $blue;
      }
    }
  }
  //Button Background
  background: radial-gradient($green 45%, transparent 45%);
  background-size: 210% 210%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  transition: background-position 1.5s ease !important;
  &:hover{
    background-position: 50% -75%;
  }
  &:after{
  }
  //Button Hover Effect
  &:hover{
    // background-color: transparent;
    color: $blue;
    &:after{
      transform: translateY(100%);
    }
  //  Saving Button Hover
    span.ai-btn-icon{
      animation-name: aiSavingButtonAnimation;
      animation-duration: 1s;
      svg{

      }
    }
  }
  &.ai-btn-dark-bg{
    transition-delay: .2s;
    span.ai-btn-icon-lottie{
      svg path{
        //transition-delay: .2s;
      }
    }
    &:hover{
      color: $white;
      span.ai-btn-icon{
        svg path{
          fill: $white;
        }
      }
      span.ai-btn-icon-lottie{
        svg path{
          fill: $white;
          stroke: $white;
        }
      }
    }
  }
  &.ai-button-white{
    border: 2px solid $white;
    background: radial-gradient($white 45%, transparent 45%);
    background-size: 210% 210%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    transition: background-position 1.5s ease !important;
    &:after{
      //background-color: $white;
    }
    &:hover{
      //color: $blue;
      background-position: 50% -75%;
    }
  }

  &.ai-button-dark-blue{
    border: 2px solid $primary-blue;
    background: radial-gradient($blue 45%, transparent 45%);
    background-size: 210% 210%;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    transition: background-position 1.5s ease !important;
    color: $white;
    &:after{
      //background-color: $white;
    }
    &:hover{
      //color: $blue;
      background-position: 50% -75%;
    }
  }

  &.ai-ml-clr{
    margin-left: 0;
  }
}
//Saving Button Animation
@keyframes aiSavingButtonAnimation {
  0%{
    transform: rotate(0);
  }
  80%{
    transform: rotate(390deg);
  }
  100%{
    transform: rotate(360deg);
  }
}
button{
  background: none;
}

//With Lottie Animation
.ai-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 8px 27px !important;
  .ai-btn-icon-lottie{
    display: flex !important;
    justify-content: center;
    align-items: center !important;
    margin-left: .2rem !important;
    width: 30px !important;
    height: 24px !important;
    div[role='button']{
      height: 30px !important;
    }
  }
  &:hover{
   //span.ai-btn-icon-lottie{
   //  svg{
   //    g {
   //      path {
   //        fill: $white !important;
   //        stroke: $white !important;
   //      }
   //    }
   //  }
   //}
  }
}
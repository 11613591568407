@mixin calculationIconContainer{
  .ai-calculation-icon-container{
    position: relative;
    svg{
      margin-right: 1.5rem;
    }
    .ai-calculation-count{
      background: $blue;
      border-radius: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white;
      font-weight: 600;
      line-height: 16px;
    }
  }
}

.ai-calculator-card{
  padding-bottom: 1.5rem;
  @include max-sm{
    padding-bottom: 1rem;
  }
  //UTILITIES
  .section-border-radius{border-radius: 15px;}
  .enquiry-section-padding{
    padding: 1rem;
    @include max-sm{
      padding: .8rem;
    }
  }
  //Color Scheme
  &.residential{
    background: $cyan;
    .enquiry-title-color{color: $blue}
    .enquiry-border-color{border-color: rgba($blue, 0.2)}
    .ai-calculator-enquiry-toggle{color:$blue; &.active{color: $blue}}
    .start-saving-button{
      background: linear-gradient(0deg, $white 50%, transparent 50%);
      background-repeat: no-repeat;
      background-size: 200% 200%;
      background-position-x: center;
      background-position-y: -100%;
      &:hover{
        background-position-y: 100%;
      }
    }
  }
  &.commercial{
    background: $blue;
    border-color: $blue;
    .enquiry-title-color{color: $cyan}
    .enquiry-border-color{border-color: rgba($white, 0.2)}
    .ai-calculator-enquiry-toggle{color:$blue; &.active{color: $white; pointer-events: none} &:hover{color: $white}}
    .start-saving-button{
      color: $white !important;
      &.active{
        color: $blue !important;
      }
      background: linear-gradient(0deg, $white 50%, transparent 50%);
      background-repeat: no-repeat;
      background-size: 200% 200%;
      background-position-x: center;
      background-position-y: -100%;
      transition: background-position .6s ease;
      &:hover{
        background-position-y: 100%;
        color: $blue !important;
      }
    }
    .ai-slider-wrapper{
      height: 40px;
      .rc-slider-rail{
        background: rgba($white, 0.3) !important;
      }
    }
  }
  //General
  border-radius: 15px;
  border: 2px solid $cyan;
  overflow: hidden;
  h4{
    font-size: 16px !important;
    font-weight: 700;
    @include max-sm{
      font-size: 16px !important;
    }
  }
  .start-saving-button{
    border-color: $white;
    margin-right: .5rem;
    padding: 5px 15px;
    //background: linear-gradient(0deg, $white 50%, transparent 50%);
    &.active{
      background-color: $white;
    }
    &:hover{}
  }

  //HEADER BUTTONS
  .ai-calculator-card-header{
    margin-bottom: 1.5rem;
    button.ai-calculator-enquiry-toggle{
      font-size: 16px;
      font-weight: 600;
      padding-top: .5rem;
      padding-bottom: .5rem;
      border: none;
      width: 50%;
      background: transparent;
      background: radial-gradient($primary-grey 45%, transparent 45%);
      background-size: 230% 230%;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      transition: background-position-y 1s ease !important;
      &:hover {
        background-position-y: -75%;
      }
      &.active{
        background: transparent;
      }
    }
    @include max-sm{
      button{
        font-size: 16px !important;
      }
    }
  }

  //Calculator Settings Block
  .ai-calculator-setting-block{
    border: 1px solid;
    border-radius: 15px;
    margin: 0 1.5rem 1rem 1.5rem;
    @include max-sm{
      margin: 0 1rem 1rem 1rem;
    }
    h4{
      margin-bottom: .5rem;
      @include max-sm{
        margin-bottom: 1rem;
      }
    }
    &:last-of-type{
      margin-bottom: 0;
    }
    .ai-slider-wrapper{
      height: 40px;
    }
    &.with-slider{
      h4{
        margin-bottom: 3rem;
      }
      @include max-sm{
        h4{
          margin-bottom: 1rem;
        }
      }
    }
  }
  .ai-calculation-display{
    margin: 1rem 1.5rem;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    column-gap: 3rem;
    @include max-sm{
      margin: 1rem 1rem;
    }
    h4{
      width: 100%;
      margin-bottom: 1rem;
    }
    .ai-calculation-block{
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      &.disabled{
        opacity: 0.4;
      }
      .ai-calculation-title{
        font-size: 16px;
        font-weight: 600;
      }
      @include calculationIconContainer;
      .ai-calculation-icon-container{
        height: 58px;
        width: 58px;
        margin-right: 1.5rem;
        svg{
          width: 100%;
          height: 100%;
        }
      }
      .ai-calculation-count {
        height: 28px;
        width: 28px;
        font-size: 16px;
        top: -14px;
        right: -14px;
      }
      @include max-sm{
        margin-bottom: .5rem;
      }
    }
    //border: 1px solid;
    &.commercial{
      column-gap: 1rem;
      margin-bottom: 0;
      .ai-calculation-block{
        margin-bottom: 0;
        display: flex;
        flex-direction: row;
        @include max-sm{
          margin-bottom: 1rem;
        }
        .ai-calculation-icon-container{
          margin-right: .5rem;
        }
        .ai-calculation-block-content{
          display: flex;
          flex-direction: column;
        }
        .ai-calculation-title{
          color: $white;
          font-weight: 500;
        }
        .ai-calculation-value{
          color: $cyan;
          font-weight: 500;
          font-size: 16px;
        }
      }
    }
  }
  .ai-calculation-running-hours{
    margin: 0 1.5rem 0 1.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: .6rem;
    align-items: center;
    @include max-sm{
      margin: 0 1rem 1rem 1rem;
    }
    h4{
      width: 100%;
      margin-bottom: 1rem;
    }
    .ai-calculation-title{
      font-size: 16px;
      font-weight: 600;
      margin: auto 1rem auto auto
    }
    @include calculationIconContainer;
    .ai-calculation-icon-container{
      height: 58px;
      width: 58px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      background: rgba($blue, 0.1);
      padding-left: .5rem;
      padding-right: .5rem;
      svg{
        margin: 0 auto;
        height: 30px;
        width: 30px;
      }
      .ai-calculation-count {
        height: 24px;
        width: 24px;
        font-size: 12px;
        top: -12px;
        right: -10px;
      }
      @include max-sm{
        width: 50px;
        height: 50px;
        .ai-calculation-count{
          height: 20px;
          width: 20px;
          font-size: 10px;
        }
      }
      &.fridge{order: 1}
      &.microwave{order: 2}
      &.kettle{order: 3}
      &.cctv{order: 4}
      &.led-lights{order: 5}
    }
    .ai-calculation-title{order: 6;}
    @include max-sm{
      .ai-calculation-icon-container{
        margin-bottom: .5rem;
      }
      .ai-calculation-title{
        margin: auto auto auto 0
      }
    }
  }

  .ai-button.ai-widget-button{
    width: calc(100% - 2rem);
    display: flex;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    margin: 1rem auto 0 auto !important;
    background-size: 220% 220%;
    svg path{fill: $white; stroke: $white}
    &.commercial{
      svg path{fill: $blue; stroke: $blue}
      &:hover{
        color: $white;
        svg path{fill: $white; stroke: $white}
      }
    }
  }
}


.rc-slider-mark-text:first-child{
  margin: 0 !important;
}
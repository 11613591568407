*{
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
}

.ai-regular {
    font-weight: 400;
}
.ai-medium {
    font-weight: 500;
}
.ai-semi-bold {
    font-weight: 600;
}
.ai-medium {
    font-weight: 700;
}
.ai-text-left{
    text-align: left !important;
}
.ai-cta{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 450px;
  background-repeat: no-repeat;
  @include max-lg{
    height: 440px;
  }
  &.ai-curved-cta{
    clip-path: ellipse(80% 100% at 50% 100%);
  }
  &.ai-straight-cta{
    background-size: cover;
  }
  .ai-cta-container{
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    h1{
      margin-bottom: 1rem;
      font-weight: 600;
      color: $white;
    }
    h2{
      margin-bottom: 1rem;
      @include max-xs{
        text-align: center;
        padding-left: 3rem;
        padding-right: 3rem;
      }
    }
    h3{
      margin-bottom: 1rem;
    }
    .ai-button{
      transition-delay: .2s;
      transition: color .5s ease;
      svg path{
        //transition-delay: .2s;
        //transition: fill .5s ease;
      }
      &:hover{
        color: $white;
        .ai-btn-icon{
          svg path{
            fill: $white !important;
            stroke: $white !important;
          }
        }
        .ai-btn-icon-lottie{
          svg path{
            fill: $white !important;
            stroke: $white !important;
          }
        }
      }
    }
  }
  &.ai-straight-green-cta{
    .ai-text-green{
    }
  }
  &.ai-commercial-residential-cta{
    height: 540px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: calc((100vw - 1200px) / 2 + 12px);
    margin-right: calc((100vw - 1200px) / 2 + 12px);
    .ai-cta-container{
      text-align: left;
      max-width: 520px;
      margin: auto auto auto 0;
      align-items: flex-start;
      h3{
        font-size: 40px !important;
        font-weight: 600 !important;
        margin-bottom: 2rem;
      }
    }
    .ai-cta-image-container{
      margin-right: 5rem;
      img{
        width:450px;
        height: auto;
      }
    }
    @include max-lg{
      margin-left: unset;
      margin-right: unset;
      padding-left: 3.875rem;
      padding-right: 3.875rem;
      .ai-cta-container{
        width: 50%;
        margin-left: 1.5rem;
      }
      .ai-cta-image-container{
        width: 50%;
        margin-right: 1.5rem;
        img{
          width:100%;
          height: auto;
        }
      }
    }
    @include max-sm{
      display: flex;
      flex-direction: column;
      height: fit-content;
      padding-top: 4rem;
      padding-bottom: 4rem;
      padding-left: 2rem;
      padding-right: 2rem;
      .ai-cta-container{
        width: 100%;
        margin: 0;
      }
      .ai-cta-image-container{
        width: 100%;
        margin: 0;
        padding-bottom: 2rem;
        text-align: center;
        img{
          width: 70%;
        }
      }
    }
  }
  &.ai-commercial-residential-cta{
    height: 540px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: calc((100vw - 1200px) / 2 + 12px);
    margin-right: calc((100vw - 1200px) / 2 + 12px);
    .ai-cta-container{
      text-align: left;
      max-width: 520px;
      margin: auto auto auto 0;
      align-items: flex-start;
      h3{
        font-size: 40px !important;
        font-weight: 600 !important;
        margin-bottom: 2rem;

      }
    }
    .ai-cta-image-container{
      margin-right: 5rem;
      img{
        width:450px;
        height: auto;
      }
    }
    @include max-lg{
      margin-left: unset;
      margin-right: unset;
      padding-left: 3.875rem;
      padding-right: 3.875rem;
      .ai-cta-container{
        width: 50%;
        margin-left: 1.5rem;
      }
      .ai-cta-image-container{
        width: 50%;
        margin-right: 1.5rem;
        img{
          width:100%;
          height: auto;
        }
      }
    }
    @include max-sm{
      display: flex;
      flex-direction: column;
      height: fit-content;
      padding-top: 4rem;
      padding-bottom: 4rem;
      padding-left: 2rem;
      padding-right: 2rem;
      .ai-cta-container{
        width: 100%;
        margin: 0;
      }
      .ai-cta-image-container{
        width: 100%;
        margin: 0;
        padding-bottom: 2rem;
      }
    }
  }
  &.ai-commercial-residential-cta{
    height: 540px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: calc((100vw - 1200px) / 2 + 12px);
    margin-right: calc((100vw - 1200px) / 2 + 12px);
    .ai-cta-container{
      text-align: left;
      max-width: 575px;
      margin: auto auto auto 0;
      align-items: flex-start;
      h3{
        font-size: 40px !important;
        font-weight: 600 !important;
        margin-bottom: 2rem;

      }
    }
    .ai-cta-image-container{
      margin-right: 5rem;
      img{
        width:450px;
        height: auto;
      }
    }
    @include max-lg{
      margin-left: unset;
      margin-right: unset;
      padding-left: 3.875rem;
      padding-right: 3.875rem;
      .ai-cta-container{
        width: 50%;
        margin-left: 1.5rem;
      }
      .ai-cta-image-container{
        width: 50%;
        margin-right: 1.5rem;
        img{
          width:100%;
          height: auto;
        }
      }
    }
    @include max-sm{
      display: flex;
      flex-direction: column;
      height: fit-content;
      padding-top: 4rem;
      padding-bottom: 4rem;
      padding-left: 2rem;
      padding-right: 2rem;
      .ai-cta-container{
        width: 100%;
        margin: 0;
      }
      .ai-cta-image-container{
        width: 100%;
        margin: 0;
        padding-bottom: 2rem;
        text-align: center;
        img{
          width: 70% !important;
        }
      }
    }
  }
}
.ai-curved-cta-container{
  height: 550px;
  clip-path: ellipse(80% 100% at 50% 100%);
  @include max-md{
    clip-path: ellipse(140% 100% at 50% 100%);
  }
  .react-parallax {
    height:100% !important;
    .ai-cta-parallax {
      height: 600px !important;
      @include fhd{
        height: unset !important;
      }
      @include  max-md{
        clip-path: ellipse(140% 100% at 50% 100%);
      }
    }
  }
}
.ai-cta-parallax{
  height: 490px !important;
  @include fhd{
    height: 567px !important;
  }
}

.ai-curved-cta-2{
  .react-parallax{
    .react-parallax-background-children{
      width: 100%;
      img{
        width: 100%;
      }
    }
  }
}
.ai-power-calculator{
  .solution-description{
    min-height: 260px;
    h2{
      margin-bottom: 1rem !important;
    }
    @include max-sm{
      padding-left: 1rem !important;
      padding-right: 1rem !important;
      height: 245px;
    }
    @include max-sm{
      padding-left: 0 !important;
      padding-right: 0 !important;
      height: auto;
    }
  }
  @include max-sm{
    & > .container{
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
  }
}
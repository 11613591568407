.rc-slider-tooltip-placement-top {
    .rc-slider-tooltip-arrow {
        display: none !important;
        border-top-color: transparent !important;
    }
}
   
.rc-slider-tooltip-hidden {
    display: block !important;
}

.rc-slider-mark-text {
    font-size: 16px !important;
    @include max-xs{
        font-size: 12px !important;
        &:first-child{
            margin-left: 1rem !important;
        }
    }
}

.rc-slider-tooltip-inner{
    font-size: 16px !important;
    height: 35px !important;
    width: 65px !important;
}

.ai-price-slider{
    @include max-xs{
        width: 100% !important;
        border-radius: 15px;
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }
    .row{
        @include max-xs{
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
        .col-md-4, .col-md-8{
            @include max-lg{
                width: 50%;
            }
            @include max-md{
                width: 100%;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .rc-slider{
                    width: 100% !important;
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                }
            }
        }
    }
}
.ai-search-card{
  background: $white;
  border-radius: 15px;
  padding: 2.5rem 3rem;
  margin-bottom: 2rem;
  h3{
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 1rem;
  }
  p{
    font-size: 14px;
    margin-bottom: 1rem !important;
  }
  .ai-button{
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.ai-search-page-title{
  font-size: 16px;
  font-weight: 400;
}
.ai-search-value{
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 0;
}
.ai-nothing-found{
  display: flex;
  justify-content: center;
  .row{
    display: flex;
    justify-content: center;
    h3{
      padding-bottom: 1rem;
      font-size: 24px;
    }
  }
  .ai-button{
    margin-top: 2rem;
    width: auto;
    padding: 8px 30px;
  }
}
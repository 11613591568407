.ai-eipopup-container{
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(255, 255, 255, 0.8);
  z-index: 99999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .ai-eipopup-gif-container{
    position: relative;
    .ai-popup-close{
      cursor: pointer;
      position: absolute;
      right: -25px;
      top: -25px;
      i{
        font-size: 22px;
      }
    }
  }
  @include max-lg{
    .ai-eipopup-gif-container{
      width: 85%;
      margin: 0 auto;
      .ai-popup-close{
        top: -35px;
        right: -35px;
        i{
          font-size: 24px;
        }

      }
      img{
        width: 100%;
      }
    }
  }
  @include max-xs{
    .ai-eipopup-gif-container{
      width: 85%;
      margin: 0 auto;
      .ai-popup-close{
        top: -25px;
        right: -25px;
        i{
          font-size: 24px;
        }

      }
      img{
        width: 100%;
      }
    }
  }
}
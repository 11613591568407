.ai-how-you-pay-intro{
  .ai-section-heading{
    padding-left: 5rem;
    padding-right: 5rem;
    @include max-md{
      padding-left: 0rem;
      padding-right: 0rem;
    }
  }
  p{
    padding-left: 1rem;
    padding-right: 1rem;
    line-height: 28px;
  }
  @include max-xs{
   & > .container{
     & > .row{
       padding-top: 2rem;
     }
   }
  }
}

.ai-how-you-pay-tabs.ai-tabs{
  .ai-tab{
    padding: 2rem;
  }
  .ai-tab-content{
    padding: 0 7rem !important;
    .row{
      padding-top: 4rem;
      padding-bottom: 4rem;
    }
  }
  img{
    width: 100%;
    height: 300px;
    object-fit: cover;
    border-radius: 15px;
  }
  .ai-tab-list{
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 0.5rem;
    flex-wrap: nowrap;
    li{
      width: 100% !important;
      margin-right: 0 !important;
      span.ai-tab-heading{
        font-size: 20px ;
        font-weight: 600;
        padding-bottom: .3rem;
      }
      span.ai-tab-text{
        font-size: 14px !important;
      }
    }
  }
}

.ai-how-you-pay-info-block{
  padding-top: 0 !important;
  padding-bottom: 2rem !important;
  .ai-info-card{
    margin-bottom: 2rem;
  }
  @include max-lg{
    width: 100% !important;
    .col-md-4{
      width: 50%;
    }
    .ai-info-card{

    }
  }
  @include max-xs{
    width: 100%;
    .col-md-4{
      padding: 0;
      width: 100%
    }
  }
}

.ai-product-benefits-curve{
  background-position-y: -85px;
  background-repeat: no-repeat;
  background-size: contain;
  .ai-section-subtitle{
    font-size: 14px;
    line-height: 24px;
  }
  &.ai-products-customer-section{
    background-position-y: 0;
    @include max-lg{
      background-size: auto !important;
      background-position: top center !important;
    }
    @include max-xs{
      background-size: auto 465px;
      background-padding: top center;
    }
  }
}

.ai-how-you-pay-cta{
  justify-content: center;
  margin-left: .5rem;
  .ai-button{
    &:first-child{
      margin-left: 0;
    }
  }
  @include max-lg{
    .row{
      flex-direction: column;
      padding-top: 0;
      .col-md-5{
        width: 100%;
        text-align: center;
        img{
          width: 80%
        }
      }
      .col-md-7{
        margin-top: 2rem;
        width: 100%;
        padding-left: 0 !important;
        h2{
          max-width: 100%;
        }
        p{
          max-width: 100%
        }
      }
    }
  }
  @include max-xs{
    .col-md-7{
      h2{
        font-size: 36px !important;
      }
      .ai-button-row{
        display: inline;
        a{
          margin: 0;
          width: auto
        }
      }
    }
  }
}

.ai-how-you-pay-info-block{
  margin-top: 1rem !important;
  width: 92% !important;
  margin: 0 auto !important;
  .col-md-4{
    margin-bottom: 2rem;
    .ai-info-card{
      height: 100%;
      p{
        margin-bottom: 0 !important;
      }
    }
  }
  @include max-xs{
    width: 100% !important;
  }
}

.ai-hyp-slider{
  .row{
    .ai-price-slider{
      width: 96%;
      margin:  0 auto;
      @include max-lg{
        width: 100% !important;
      }
    }
  }
  @include max-xs{
    padding: 0 !important;
  }
}

.hyp-info-block-btn{
  margin-bottom: 6rem;
}

.ai-hyp-cta-left {

}
.ai-price-slider{
    background-image: linear-gradient(to right, $cyan, $green);
    width: 90% !important;
    padding: 0 2rem !important;
    margin: auto;
    border-radius: 0 20px 20px 20px;
    position: relative;
    overflow: hidden;
     @include max-sm{
         border-radius: 0 0 15px 15px !important;
     }
    .ai-slider-icon {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 11%;
        overflow: hidden;
        height: fit-content;
        animation: sliderIcon 5s ease infinite;

        img {
            padding: .5rem;
            height: 60px;
            animation: sliderIconImage 5s ease infinite;
        }

        @media screen and (min-width: 992px) and (max-width: 1180px) {
            padding-left: 0;
            top: 8rem;
            animation-name: sliderIcon;
            img {
                height: 56px;
            }
        }
        @media screen and (min-width: 600px) and (max-width: 991px) {
            padding-left: 0;
            top: 8rem;
            animation-name: sliderIcon;
            img {
                height: 56px;
            }
        }
        @media screen and (max-width: 599px) and (max-height: 900px) {
            padding-left: 0;
            top: 7rem;
            animation-name: sliderIconMobile;
            img {
                height: 56px;
            }
        }


        @media screen and (max-width: 599px) and (max-height: 750px) {
            padding-left: 0;
            top: 7rem;
            animation-name: sliderIconMobile;
            img {
                height: 56px;
            }
        }
        @media screen and (max-width: 400px) and (max-height: 850px) {
            padding-left: 0;
            top: 8rem;
            animation-name: sliderIconMobile;
            img {
                height: 56px;
            }
        }
    }
    .row{
        display: flex;
        align-items: center;
        width: 100%;
        padding: 3rem 0;
        .ai-range-slider{
            margin: 0;
        }
        h3{
            font-weight: 600;
            color: $blue;
        }
        p{
            font-size: 12px;
            font-weight: 500;
            color: $blue;
        }
        .ai-slider{
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            .rc-slider-with-marks{
                width: 91% !important;
                margin-bottom: .5rem;
            }
        }
        .ai-slider-button {
            display: flex;
            justify-content: center;
            align-items: center;
            .ai-button {
                background: $blue;
                // transition-delay: .1s;
                transition-timing-function: 1.2s;
                font-size: 16px;
                padding: .7rem 1.2rem !important;
                vertical-align: middle;
                display: flex;
                align-items: center;
                background: radial-gradient($primary-blue, 44%, transparent 45%);
                // background: radial-gradient($primary-blue, 44%, $white 45%);
                background-size: 275% 275%;
                background-position: 50% 50%;
                color: $white;
                white-space: nowrap;
                border-color: $primary-blue;

                transition: background-position 1.5s ease !important;
                span{
                    margin-left: 0.6rem;
                }

                svg path {
                    fill: $white;
                    stroke: $white;
                    transition-delay: .2s;
                }
                &:hover {
                    color: $primary-blue;
                    background-position: 50% -29%;
                    svg path {
                        fill: $primary-blue;
                        stroke: $primary-blue;
                    }
                }
            }
            @include max-lg{
                margin-top: 2rem;
            }
        }
    }
}
.rc-slider-rail{
    background-color: rgba($blue, 0.3) !important;
}
.rc-slider-tooltip {
    z-index: 2;
    padding: .5rem 1rem;
    height: auto;
    width: auto;
    font-size: 18px;
    .rc-slider-tooltip-inner{
        box-shadow: none;
    }
}

@keyframes sliderIcon {
    0%{
        transform: translateX(-150px);
        opacity: 1;
        }
    10%{
        transform: translateX(-150px);
        opacity: 1;
    }
    50%{
        transform: translateX(150px);
        opacity: 1;
    }
    60%{
        transform: translateX(150px);
        opacity: 1;
    }
    100% {
        transform: translateX(150px);
        opacity: 0;
    }
}

@keyframes sliderIconImage {
    0%{transform: translateY(60px)}
    10%{transform: translateY(0)}
    100%{transform: translateY(0)}
}

@keyframes sliderIconMobile {
    0%{
        transform: translateX(-70px);
        opacity: 1;
    }
    10%{
        transform: translateX(-70px);
        opacity: 1;
    }
    50%{
        transform: translateX(70px);
        opacity: 1;
    }
    60%{
        transform: translateX(70px);
        opacity: 1;
    }
    100% {
        transform: translateX(70px);
        opacity: 0;
    }
}

//New Additions
.slider-wrapper{
    h1{
        margin-bottom: 0;
    }
    .slider-buttons{
        border-radius: 50px 50px 0 0;
        width: 90%;
        margin:  0 auto;
         @include max-sm{
             margin-left: 0;
             margin-right: 0;
             width: 100%;
             display: flex;
         }
        button{
            width: 255px;
            height: 45px;
            border: 1px solid $cyan;
            border-radius: 20px 0 0 0;
            font-weight: 500;
            color: $white;
            font-size: 18px;
            @include max-sm{
                width: 50%;
                border-radius: 15px 0 0 0;
                font-size: 16px;
            }
            &.active{
                color: $blue;
                background: $cyan;
            }
            &:last-child{
                border-radius: 0 20px 0 0;
                @include max-sm{
                    border-radius: 0 15px 0 0;
                }
            }
        }
    }
}
.ai-single-pagination-container{
  height: 200px;
  background-size: cover;
  background-position: center center;
  position: relative;
  @include max-xs{
    height: 150px;
  }
  .ai-single-pagination-link{
    text-decoration: none;
    color: $white ;
    font-weight: 600;
    font-size: 26px;
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    z-index: 2;
    overflow: hidden;
    &:after{
      content: "";
      height: 100%;
      width: 100%;
      position: absolute;
      background-color: rgba($blue, 0.6);
      top: 0;
      left: 0;
      z-index: 0;
      transition-delay: .2s;
    }
    &:before{
      content: "";
      height: 50px;
      width: 50px;
      border-radius: 100%;
      position: absolute;
      background-color: rgba($cyan, 0.9);
      bottom: -50px;
      left: -50px;
      z-index: 1;
      transition: transform 1s ease;
    }
    &:hover{
      .ai-single-pagination{
        i{
          color: $blue;
        }
        .text{
          span{
            color: $blue;
          }
          color: $blue;
        }
      }
      &:before{
        transform: scale(50);
      }
      &:after{
        top: -100%;
      }
    }
  }
  .ai-single-pagination{
    display: flex;
    width: 88%;
    align-items: center;
    position: relative;
    z-index: 2;
    i{
      font-size: 30px;
      transition: transform .5s ease;
    }
    .text{
      span{
        display: block;
        width: 100%;
        color: $green;
        font-weight: 400;
        font-size: 16px;
      }
      @include max-xs{
        font-size: 16px;
        margin-
        span{
          font-size: 14px;
        }
      }
    }
    &.ai-single-pagination-prev{
      margin: auto 0 auto auto;
      justify-content: flex-start;
      .text{
        margin-left: 6rem;
        margin-right: 2rem;
      }
      &:hover{
        i{
          transform: translateX(-15px);
        }
      }
    }
    &.ai-single-pagination-next{
      margin: auto auto auto 0;
      justify-content: flex-end;
      .text{
        margin-right: 6rem;
        margin-left: 2rem;
      }
      &:hover{
        i{
          transform: translateX(15px);
        }
      }
    }
  }
}

.ai-single-pagination-container.ai-prev-post .ai-single-pagination-link:before{
  left: auto;
  right: -50px;
}
header.ai-header-main{
  max-width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  padding: 0;
  &.ai-dark-background{
    background-color: #0B223E;
  }
  .row{
    align-items: center;
    padding: 0
  }
  //Header Logo
  .ai-brand{
    img{
      width: 100%;
      height: auto;
      transition: width 1s ease;
      padding-right: 1rem;
    }
  }
//  Header Navigation
  .ai-navigation{
    width: 68%;
    display: inline-flex;
    margin-right: 1rem;
    .ai-header-button-mobile{
      display: none;
    }
    ul{
      width: 100%;
      list-style: none;
      display: flex;
      justify-content: space-between;
      padding-left: 0;
      margin-bottom: 0;
      li{
        position: relative;
        display: inline-flex;
        flex-direction: row;
        i{
          cursor: pointer;
        }
        a{
          padding: 3rem 0;
          transition: padding .2s ease;
          &.ai-active-nav-link{
            color: $green;
            i{
              color: $green;
            }
          }
        }
        i{
          color: $white;
        }
        &:hover{
          a{
            color: $green;
          }
          i{
            color: $green;
          }
        }
      //  Dropdown menus
        ul.ai-dropdown-menu{
          position: absolute;
          top: 97px;
          min-width: 230px;
          background-color: $cyan;
          padding: 20px;
          left: -20px;
          border-radius: 0px 15px 15px 15px;
          display: none;
          flex-direction: column;
          li{
            padding: 0 0 1rem 0;
            &:hover{
              font-weight: 600;
            }
            &:last-child{
              padding-bottom: 0;
            }
            a{
              font-size: 14px;
              text-decoration: none;
              color: $blue;
              padding: 0;
              position: relative;
              &.ai-active-nav-link{
                font-weight: 600;
              }
            }
          }


          &.ai-grandchild-dropdown-menu{
            padding-inline: 0;
            min-width: 231px;
            & > li{
              & > a, .ai-link-container{
                padding-inline: 20px;
                @media screen and (max-width: 1199px){
                  padding-right: 0px;
                }
              }
            }
            .ai-grandchild-dropdown-menu{
              display: none;
              .ai-link-container{
                padding-left: 0;
                span{
                  line-height: 30px;
                }
                a{
                  padding-inline: 20px;
                }
              }
            }
          }
          &.ai-split-list-dropdown{
            @include max-lg{
              & > li{
                &:nth-child(1){order: 1}
                &:nth-child(2){order: 5}
                &:nth-child(3){order: 2}
                &:nth-child(4){order: 6}
                &:nth-child(5){order: 3}
                &:nth-child(6){order: 7}
                &:nth-child(7){order: 4}
                &:nth-child(8){order: 8}
              }
            }
            @media screen and (min-width: 1200px) {
              min-width: 440px;
              border-radius: 15px 0 15px 15px;
              flex-direction: row;
              flex-wrap: wrap;
              left: unset;
              right: 0;
              & > li{
                width: 50%;
                &:nth-child(7), &:nth-child(8){
                  padding-bottom: 0;
                }
                ul{
                  min-width: 240px;
                  li{
                    white-space: nowrap;
                    ul{
                      min-width: unset !important;
                      li{
                        white-space: normal;
                        display: flex;
                        flex-direction: column;
                        &:after{
                          width: 110%;
                          content:"";
                          height: 0;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        &.dropdown-link{}
        &:hover{
          & > ul.ai-dropdown-menu{
            display: flex;
          }
        }
      }
      .ai-dropdown-link{
        .ai-link-dropdown-menu{
          li a{
            font-weight: 200;
            &:hover{
              font-weight: 600;
            }
          }
        }
      }
    }
   
    @media screen and (min-width: 1200px){
      & > ul{
        .ai-dropdown-link{
          position: relative;
          i{
            color: #0B223E !important;
            transition: transform .05s ease;
          }
          .ai-link-dropdown-menu{
            position: absolute;
            top: -50%;
            left: 100%;
            background-color: #fff;
            display: none !important;
            min-width: 262px;

            &.ai-link-dropdown-menu-wide{
              min-width: 303px;
            }

            &:hover{
              display: flex;
            }
          }

          &:hover{
            & > .ai-link-container{
              i{
                transform: rotate(-90deg) translateX(-2px);
              }
            }
            & > .ai-link-dropdown-menu{
              display: flex !important;
            }
          }
        }
      }
    }
    @media screen and (max-width: 1199px){
      & > ul{
        .ai-dropdown-link{
          .ai-link-dropdown-menu{
            li a{
              padding-left: 2rem;
            }
          }
        }
      }
    }
  }
  .ai-header-right{
    width: 25%;
    display: inline-flex;
    justify-content: flex-end;
    align-items: center;
    .ai-search-toggle{
      display: flex;
      i{
        color: $white;
        cursor: pointer;
        font-size: 16px;
        line-height: 16px;
        &:hover{
          color: $green;
          font-weight: 600;
        }
      }
    }
    .ai-header-button{
      transition: color .5s ease;
      transition-delay: .5s;
      margin-left: 1.2rem;
      @include xl-wide{
        margin-left: 1rem !important;
      }
      //svg path{
      //  transition: fill .5s ease;
      //  transition-delay: .5s;
      //}
      .ai-mobile-nav-toggle{
        display: none;
        width: 50px;
        height: 50px;
        background: $green;
      }
      &:hover{
        color: $white;
        svg path{
          fill: $white;
          stroke: $white;
        }
      }
    }

  }
  &.scrolled{
    background-color: $blue;
    .ai-brand{
      img{
        width: 70%;
      }
    }
    ul{
      li{
        span{
          a{
            padding: 1.8rem 0;
          }
        }
        ul.ai-dropdown-menu{
          top:82px;
        }
      }
    }
  }
  &.ai-nav-open{
    .ai-mobile-nav-toggle{
      .ai-bar{
        transition: transform .5s ease;
        margin-bottom: 0 !important;
        &:nth-child(1){
          transform: rotate(45deg) translateY(1px);
        }
        &:nth-child(2){
          display: none;
        }
        &:nth-child(3){
          transform: rotate(-45deg) translateY(-1px);
        }
      }
    }
  }
//  Mobile Navigation
  @include max-lg{
    padding: 1rem 0;
    .ai-navigation{
      display: none;
    }
    .ai-header-right{
      display: flex;
      justify-content: flex-end;
      width: auto;
      .ai-mobile-nav-toggle{
        display: block;
        margin-left: 1rem;
        cursor: pointer;
        .ai-bar{
          width: 22px;
          height: 2px;
          background-color: $white;
          margin-bottom: 5px;
        }
      }
      .ai-header-button{
        display: none;
      }
    }
    &.ai-nav-open{
      background: $blue;
      .ai-navigation{
        display: flex !important;
        flex-direction: column;
        align-items: flex-start;
        position: fixed;
        left: 0;
        top: 90px;
        height: 100vh;
        width: 100%;
        background: $blue;
        padding-left: 3rem;
        padding-right: 3rem;
        padding-top: 2rem;
        @include max-lg{
          top: 74px;
        }
        .ai-header-button-mobile{
          display: inline-flex;
          margin-top: 0;
        }
        ul{
          display: flex;
          flex-direction: column;
          width: 100%;
          li{
            padding: 0 0 1rem 0;
            flex-direction: column;
            .ai-link{
              display: flex;
              justify-content: space-between;
              width: 100%;
              padding: 0;
            }
            span.active-dropdown {
              i{
                transform: rotate(180deg);
                padding: 0;
              }
              & + .ai-dropdown-menu {
                display: flex !important;
                position: static;
                background: none;
                padding-top: .5rem;
                padding-bottom: .5rem;
                padding-left: 1rem;
                li{
                  padding: 0;
                  a{
                    color: $white;
                    line-height:30px;
                  }
                }
              }
            }
            &:hover{
              ul.ai-dropdown-menu{
                display: none;
              }
            }
          }
        }
      }
      &.scrolled{
        .ai-navigation{
          ul.ai-dropdown-menu{}
        }
      }
    }
  }
  //I'm here
  @include max-sm{
    .container{
      padding-left: 2rem;
      padding-right: 2rem;
      .row{
        display: flex;
        justify-content: space-between;
        & > div{
          width: auto;
        }
        .ai-brand{
          width: 50%;
          display: inline-block;
        }
      }
    }

    .ai-header-right{
      display: inline-flex;
    }
    .ai-navigation{
      padding-left: 2rem !important;
      padding-right: 2rem !important;
      top: 66px;
      .ai-brand{
        img{
          width: 40%;
        }
      }
      ul{
        width: 100%;
        li{
          span.active-dropdown{
          }
        }
      }
      .ai-header-button{
        width: 100%;
        margin: auto;
        text-align: center;
      }
    }
  }
}

@media screen and (max-width: 1199px){
  header.ai-header-main .ai-navigation ul li{
    &.ai-no-dropdown > span{
      span{
        display: none;
      }
    }
  }
}